const stg = {
  production: true,
  aws: {
    region: "eu-west-1",
    cognito: {
      userPoolId: "eu-west-1_xQSI5PZB6",
      userPoolAppClientId: "1tinml8qtr0grs0d5gsd3u7t63",
      authDomain: "auth.vbcpcen.ucc-stg.vodafone.com",
      authRedirectSignIn: "https://adminportal.vbcpcen.ucc-stg.vodafone.com/",
      authRedirectSignOut: "https://adminportal.vbcpcen.ucc-stg.vodafone.com/signout",
    },
    apiGateway: {
      basePath: "https://api.vbcpcen.ucc-stg.vodafone.com/portal",
    },
  },
  framework: {
    archivedRoutingProfilePrefix: "DELETED",
  },
  externalLinks: {
    verint: "https://cen-mmr-v15.cenverpp.centrica.com/wfo",
  },
};

export default stg;
